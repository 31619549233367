import Vue from 'vue';
import staticPagesMixin from './static-pages-mixin';

import MainVue from './main-vue';

import VueResource from 'vue-resource';
import { Vuelidate } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mustBeChecked, onlyAlphaNumeric, onlyAlphaNumericWithApst } from './helper-functions/helper-functions';
import { goToFirstError } from './helper-functions/services';
Vue.use(Vuelidate);
Vue.use(VueResource);

import Clipboard from 'clipboard'; //only visitors-medical-insurance-corrections
import BackButton from './components/forms/component.back-button.vue';


import { ibSpinOpts } from './helper-functions/applicationCommonValues';  

$(document).ready(function() { //only visitors-medical-insurance-corrections
    try {
        var clipboard = new Clipboard('.js-Clipboard');
        clipboard.on('success', function(event) {
            event.clearSelection();
            var txtForCopyto = window.globalLanguage.copyToClipboard,
                txtForCopiedto = window.globalLanguage.copiedToClipboard;

            event.trigger.textContent = txtForCopiedto;
            window.setTimeout(function() {
                event.trigger.textContent = txtForCopyto;
            }, 2000);

        });

        require('./modules/back-button')
    }
    catch(e) {
    }

    $(window).resize(function() {
        var viewportWidth = $(window).outerWidth();
        if($('.c-Plan-list__thumb-btn').hasClass('is-Active') && viewportWidth < 992) {
            $('.c-Plan-list__container').removeClass('is-Thumb-layout');
            $('.c-Plan-list__container').addClass('is-List-layout');
        }
        else if($('.c-Plan-list__thumb-btn').hasClass('is-Active') && viewportWidth > 992) {
            $('.c-Plan-list__container').removeClass('is-List-layout');
            $('.c-Plan-list__container').addClass('is-Thumb-layout');
        }
    });

});

async function sha256(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return Array.from(new Uint8Array(hash))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
}

new MainVue({
    el: '#app',
    mixins: [staticPagesMixin],
    components: {
        'back-button': BackButton
    },
    data() {
        return {
            // Dental Vision Schedule
            state: "", // only used in dental-schedule
            stateSet1: false, // only used in dental-schedule
            stateSet2: false,
            stateSet3: false,
            stateSet4: false,
            stateSet5: false,
            stateSet6: false,
            stateSet7: false,
            stateSet8: false,  // only used in dental-schedule
            correctionType: 'default', //only in visitors-medical-insurance-corrections
            requestMethod: 'default', //only in visitors-medical-insurance-corrections
            copiedText:'', //only in visitors-medical-insurance-corrections
            isFAQItemVisible: null,
            //Tast of home
            form: {
                firstName: "",
                lastName: "",
                age: null,
                university: "",
                visaType: "",
                countryOfOrigin: "",
                emailAddress: "",
                text: "",
                agree: false
            },
            formSending: false,
            submitted: false,
            formError: [],
            alreadySubmitted: false,
            isLangMenuOpen: false,
            winners: [
                {name: "Aide A.", school: "Polk State College"},
                {name: "Kalpana A.", school: "Home"},
                {name: "Neeharika B.", school: "University of Pittsburgh"},
                {name: "Bambi B.", school: "Cerro Cosso Community College"},
                {name: "Dana B.", school: "Hudson Bailey"},
                {name: "Santosh B.", school: "The Ohio State University"},
                {name: "Anmol B.", school: "University of Cincinnati"},
                {name: "Gregoria B.", school: "N/A"},
                {name: "Tavon B.", school: "Arizona University Global"},
                {name: "Khalilah B.", school: "University of Phoenix"},
                {name: "Shavin H.", school: "Lewis University"},
                {name: "Caitlyn C.", school: "Bryant and Stratton"},
                {name: "Ashlynn C.", school: "Kennesaw State University"},
                {name: "Amanda C.", school: "University of Phoenix"},
                {name: "Natalee C.", school: "Urban College of Boston"},
                {name: "Manuel C.", school: "Greystone College"},
                {name: "Leslie D.", school: "Los Angeles Valley College"},
                {name: "Sophia D.", school: "N/A"},
                {name: "Varun D.", school: "Arizona State University"},
                {name: "Den D.", school: "University of Florida"},
                {name: "Mohammed E.", school: "N/A"},
                {name: "Angelina F.", school: "DeVry"},
                {name: "Fareha F.", school: "SUNY Schenectady Community College"},
                {name: "Valeria F.", school: "Zoni Language Center"},
                {name: "Kristina G.", school: "Butler"},
                {name: "Virginia G.", school: "Columbus State University"},
                {name: "Trisha G.", school: "University of Colorado Denver"},
                {name: "Bardia G.", school: "SMC"},
                {name: "Sandra G.", school: "Georgia State University"},
                {name: "Naomi H.", school: "American College of Greece"},
                {name: "Mahmudul H.", school: "University of Florida"},
                {name: "Mahmudul H.", school: "Pennsylvania State University"},
                {name: "Tyler H.", school: "Gaston College"},
                {name: "Sharafat H.", school: "University of Arkansas at Little Rock"},
                {name: "April H.", school: "ICC"},
                {name: "Mehreen I.", school: "N/A"},
                {name: "Mohaimenul I.", school: "University of Alabama"},
                {name: "Juhi J.", school: "University of Southern California"},
                {name: "Pravalya K.", school: "Central Michigan University"},
                {name: "Michael K.", school: "Wallace Community College"},
                {name: "Kathlena L.", school: "Tri-C"},
                {name: "Zamesha L.", school: "Ultimate Medical Academy"},
                {name: "Tamiko L.", school: "Family University"},
                {name: "George L.", school: "Ritiard"},
                {name: "Subrahmanya M.", school: "Mount Vernon Nazarene University"},
                {name: "Al Jaber M.", school: "George Mason University"},
                {name: "Namit M.", school: "N/A"},
                {name: "Himabindu M.", school: "Wayland Baptist"},
                {name: "Alaina M.", school: "Malachis International Ministries"},
                {name: "Rohith M.", school: "University at Buffalo"},
                {name: "Dawn M.", school: "Liberty University"},
                {name: "Sana M.", school: "N/A"},
                {name: "Abinash M.", school: "Illinois Institute of Technology"},
                {name: "Shavanthi N.", school: "CIPM"},
                {name: "Shiza N.", school: "Kent State University"},
                {name: "Eletise N.", school: "Colorado Technical University"},
                {name: "Daniella O.", school: "Norquest College"},
                {name: "Yvonne P.", school: "N/A"},
                {name: "Esha P.", school: "Carnegie Mellon University"},
                {name: "Margaret P.", school: "Sawyer Business College"},
                {name: "Evan P.", school: "University of Pittsburgh"},
                {name: "Shravan R.", school: "Rivier University"},
                {name: "Subhalesh P.", school: "University of Houston"},
                {name: "Adarsh P.", school: "New Jersey Institute of Technology"},
                {name: "Fathima R.", school: "University of the People"},
                {name: "Mazbaur R.", school: "Georgia State University"},
                {name: "Kavya R.", school: "University of Newhaven"},
                {name: "Davana R.", school: "Antelope Valley College"},
                {name: "Rebecca R.", school: "Texas State University"},
                {name: "Serenity W.", school: "St. Paul Tech"},
                {name: "Roberta S.", school: "Central Arizona College"},
                {name: "Pranav S.", school: "Texas Tech University"},
                {name: "Shanita S.", school: "N/A"},
                {name: "Erika S.", school: "UNC School of the Arts"},
                {name: "Rupinder S.", school: "Rutgers University"},
                {name: "Annette S.", school: "N/A"},
                {name: "Zoe S.", school: "University of Cincinnati"},
                {name: "Jan S.", school: "Aurora Central High School"},
                {name: "Monica S.", school: "CCUSA"},
                {name: "Naquarion S.", school: "Old Dominion University"},
                {name: "Brandie S.", school: "Delta College"},
                {name: "Sangam S.", school: "Fisk University"},
                {name: "Desire T.", school: "EMCC"},
                {name: "Pranay T.", school: "University of North Texas"},
                {name: "Yaug", school: "N/A"},
                {name: "Bhargavi V.", school: "University of Missouri Kansas City"},
                {name: "Taniya V.", school: "Houston University"},
                {name: "Margaret W.", school: "N/A"},
                {name: "Sophia W.", school: "Capella University"},
                {name: "Takia W.", school: "American River College"},
                {name: "Morgane W.", school: "South University of Georgia"},
                {name: "Tiffany W.", school: "Ultimate Medical Academy"},
                {name: "Marilyn W.", school: "N/A"},
                {name: "Shelley W.", school: "South University Savannah"},
                {name: "Shubh Y.", school: "Tennessee State University"},
                {name: "Kamogelo", school: "Dallas College"},
                {name: "Syamali", school: "University of Calcutta"},
                {name: "Jaskomalveer", school: "Assiniboine Community College"},
                {name: "Irene", school: "South Plains Community College"},
                {name: "Yadnil", school: "Dr. DY Patil University"}
            ]
        }
    },
    validations () {
        let validations = {
            form: {
                firstName: {
                    required,
                    onlyAlphaNumeric
                },
                lastName: {
                    required,
                    onlyAlphaNumeric
                },
                age: {
                    required,
                    onlyAlphaNumeric
                },
                university: {
                    required,
                    onlyAlphaNumeric
                },
                visaType: {
                    required
                },
                countryOfOrigin: {
                    required
                },
                emailAddress: {
                    required,
                    email
                },
                text: {
                    required,
                    onlyAlphaNumericWithApst
                },
                agree: {
                    mustBeChecked
                }
            }
        }
        return validations;
    },
    watch: {
        correctionType: function(newval) { //only in visitors-medical-insurance-corrections
            setTimeout(()=>{
                var name = this.$el.querySelector('#'+ newval);
                if(name) {
                    this.copiedText = name.innerText ? name.innerText : name.textContent;
                }
            },500);
        },
    },
    computed:{
        numberOfWords() {
            const count = (s) => [...s.matchAll(/\b\w+\b/g)].length;
            return count(this.form.text);
        },
        itemPerRow() {
            if(window.innerWidth > 768) return 3;
            else if(window.innerWidth > 544) return 2;
            return 1;
        },
        totalRows() {
            return Math.ceil(this.winners.length / this.itemPerRow);
        }
    },
    mounted () {
        if (this.$refs.aboutFavFood) {
            this.$refs.aboutFavFood.addEventListener('keypress', (e) => this.restrictToHundred(e));
            this.$refs.aboutFavFood.addEventListener('paste', () => {
                setTimeout(() => {
                    const words = this.form.text.trim().split(/\s+/).filter(word => word !== '');
                    if (words.length >= 100) {
                        this.form.text = words.slice(0, 100).join(' '); // Trim the excess words
                      }
                },150);
            })
        }
    },
    methods:{
        copyToClipboard: function () { // should only in visitors-medical-insurance-corrections
            var name = this.$el.querySelector('#'+this.correctionType);
            if(name) {
                this.copiedText=name.innerText ? name.innerText : name.textContent;
            }
        },
        pressroomEmail (evt) { //press-room  and  in-the-media
            window.location.href = 'mailto:marketing@insubuy.com?subject='+evt.target.getAttribute('data-subject');
        },
        selectedState: function() { //only used in dental-schedule
            // Dental Vision Schedule
            this.stateSet1 = false;
            this.stateSet2 = false;
            this.stateSet3 = false;
            this.stateSet4 = false;
            this.stateSet5 = false;
            this.stateSet6 = false;
            this.stateSet7 = false;
            this.stateSet8 = false;

            // 501
            if (
                this.state == "AL" ||
                this.state == "FL" ||
                this.state == "GA" ||
                this.state == "IL" ||
                this.state == "KS" ||
                this.state == "KY" ||
                this.state == "LA" ||
                this.state == "MN" ||
                this.state == "MS" ||
                this.state == "MO" ||
                this.state == "NE" ||
                this.state == "OH" ||
                this.state == "OK" ||
                this.state == "PA" ||
                this.state == "TN" ||
                this.state == "TX" ||
                this.state == "VA" ||
                this.state == "PR"
                )
            {
                this.stateSet1 = true;
            } // 502
            else if (
                this.state == 'AZ' ||
                this.state == 'CO' ||
                this.state == 'IN' ||
                this.state == 'MD' ||
                this.state == 'MI' ||
                this.state == 'NV' ||
                this.state == 'NM'
                )
            {
                this.stateSet2 = true;
            } // 503
            else if (
                this.state == 'AR' ||
                this.state == 'HI' ||
                this.state == 'MA' ||
                this.state == 'NJ' ||
                this.state == 'ND' ||
                this.state == 'SD' ||
                this.state == 'WV'
                )
            {
                this.stateSet3 = true;
            } // 504
            else if (
                this.state == 'DC' ||
                this.state == 'IA' ||
                this.state == 'NY' ||
                this.state == 'NC' ||
                this.state == 'SC'
                )
            {
                this.stateSet4 = true;
            } // 505
            else if (
                this.state == 'ID' ||
                this.state == 'ME' ||
                this.state == 'OR' ||
                this.state == 'UT' ||
                this.state == 'WI' ||
                this.state == 'WY' ||
                this.state == 'CT' ||
                this.state == 'MT'
                )
            {
                this.stateSet5 = true;
            } // 506
            else if (
                this.state == 'AK' ||
                this.state == 'DE' ||
                this.state == 'NH' ||
                this.state == 'RI'
                )
            {
                this.stateSet6 = true;
            } // 507
            else if (
                this.state == 'CA'
                )
            {
                this.stateSet7 = true;
            } // Not support
            else if (
                this.state == 'VT' ||
                this.state == 'WA' ||
                this.state == 'AS' ||
                this.state == 'FM' ||
                this.state == 'GU' ||
                this.state == 'MH' ||
                this.state == 'MP' ||
                this.state == 'PW' ||
                this.state == 'VI'
                )
            {
                this.stateSet8 = true;
            }
        },
        toggleFAQItem: function(value) {
            
            if(this.isFAQItemVisible!==value){
                this.isFAQItemVisible = value;
            }
            else {
                this.isFAQItemVisible = null;
            }
        
        },
        submitForm() {
            this.$v.$touch();
            
            if (!this.$v.$invalid) {
                var spinTarget = this.$refs.btnspinner;
                var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);
                this.formSending = true;
                this.formError = [];
                this.$http.post('/api/taste-of-home-giveaway/', this.form).then(res => {
                    const responseBody = res.body;
                    if (responseBody.success === false) {
                        if (responseBody.errors && responseBody.errors.length) {
                            this.formError = responseBody.errors;
                            this.formError.forEach(err => {
                                if (err.indexOf('already')) {
                                    this.submitted = true;
                                    this.alreadySubmitted = true;
                                }
                            });
                        }
                    } else {
                        this.submitted = true;
                    }

                    if (this.submitted) {
                        try {
                            window.dataLayer.push({
                                event: 'homesick',
                                email: this.form.emailAddress,
                            });
                            
                            sha256(this.form.emailAddress).then(hashedEmail => {
                                fbq('trackCustom', 'homesick', {
                                    event: 'Form Submission',
                                    email: hashedEmail,
                                    timestamp: new Date().toISOString(),
                                    formName: 'Homesick',
                                });
                            });
                        } catch (error) {
                            console.error('Error in hashing', error);
                        }

                        setTimeout(()=> {
                            $('html, body').animate({
                                scrollTop: $("#thankyouForm").offset().top - 50
                            }, 'slow');
                        },800);
                    }
                    this.formSending = false;
                })
            } else {
                goToFirstError('o-Valid-msg');
            }
        },
        scrollToForm () {
            $('html, body').animate({
                scrollTop: $("#apply-form").offset().top - 50
            }, 'fast');
        },
        restrictToHundred (e) {
            if (this.numberOfWords >= 100) e.preventDefault();
        },
        inputNumber() {
            if(this.form.age) this.form.age = this.form.age.replace(/[^0-9]/g, "");
        },
        toggleLang() {
            this.isLangMenuOpen = !this.isLangMenuOpen;
        }
    }
})