<template>
    <a 
        id="back_to_qt"
        v-if="fullQuoteId" 
        ref="backToQt"
        :href="retrieveQuoteUrl + '?id='+fullQuoteId" 
        class="o-Btn o-Btn--small o-Btn--prim o-Btn--icon__wrapper c-Plan-h__quote-purchase-btn o-Btn--yellow-muted"
        :class="classes"
    >
        <span class="c-Nav-M__btn-icon">
            <svg viewBox="0 0 16 16" class="o-Icon-heading__svg c-Nav-M__btn-svg">
                <use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#back-btn-arrow" class="o-Icon-heading__inner"></use>
            </svg>
        </span>
        <span class="c-Nav-M__btn-text">{{ txtLabel }}</span>
    </a>
</template>

<script>
    import { getItemSessionStorage } from '../../helper-functions/services';
    export default {
        name: "BackButton",
        props: {
            classes: {
                type: String,
                default: ''
            },
            txtLabel: {
                type: String,
                default: 'Back'
            }
        },
        data () {
            return {
                fullQuoteId: ''
            }
        },
        computed: {
            retrieveQuoteUrl() {
                if(window.qFormLang?.RetrieveQuoteUrl) return window.qFormLang.RetrieveQuoteUrl;
                return '/retrieve-insurance-quotes/';
            }
        },
        created () {
            const fullQuoteId = getItemSessionStorage('fullQuoteId');
            if (fullQuoteId) {
                this.fullQuoteId = fullQuoteId;
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const id = urlParams.get('fullquoteid');
                if(id) {
                    this.fullQuoteId = id;
                }
            }
        },
        mounted () {
            // To add space between the buttons (article buttons and back to quote) in Articles (Guide esp)
            if (this.$refs.backToQt && this.fullQuoteId) {
                let childElms = this.$refs.backToQt.parentElement.children;
                if (childElms) {
                    childElms = Array.from(childElms);
                    childElms.forEach(element => {
                        if (element.id != 'back_to_qt' && element.classList.contains('u-Ma-r-0')) {
                            element.classList.remove('u-Ma-r-0');
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>
#back_to_qt {
    width: 100%;
}
@media (min-width: 540px) {
    body[lang="es"] #back_to_qt {
        max-width: 250px;
    }
    #back_to_qt {
        width: auto;
    }
}
</style>