(function($) {
    $('.js-Back-btn').click(function () {
        window.history.back();
    });
    $('.js-Back-btn--quote').click(function () {
        if(window.ibJS && window.ibJS.quoteResultsPagePath) {
            window.location = window.ibJS.quoteResultsPagePath;
        } else {
            window.history.back();
        }
    });
})(jQuery);
